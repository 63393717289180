<template>
  <v-app>
    <v-container>
      <v-row
        class="fill-height"
        align="center"
        justify="center">
        <v-col cols="12" sm="10" md="8">
          <v-card>
            <v-slide-y-transition mode="out-in">
              <v-card-text v-if="isLoading" key="loading">
                Don't close or reload this page.
                Verifing e-mail address...
                <v-progress-linear indeterminate/>
              </v-card-text>
              <v-card-text v-if="success" key="success">
                <v-alert type="success">
                  E-Mail address successfully verified
                </v-alert>
              </v-card-text>
              <v-card-text v-else key="error">
                <v-alert type="error">
                  Could not verify e-mail address
                </v-alert>
              </v-card-text>
            </v-slide-y-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    props: {
      address: {
        type: String,
        required: true
      },
      id: {
        type: Number,
        required: true
      },
      secret: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        isLoading: true,
        success: false,
        error: false
      }
    },

    created () {
      document.title = 'E-Mail Verification'
      this.submit ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit () {
        this.isLoading = true
        this.error = false
        this.success = false

        this.fetchData ({
          op: 'wap/verify',
          params: {
            address: this.address,
            id: this.id,
            secret: this.secret
          },
          cb: data => {
            this.success = true
          },
          cbError: () => {
            this.error = true
          },
          cbCatch: () => {
            this.error = true
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
